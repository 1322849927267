<template>
  <main id="package-page-next" class="next-container">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 mobile-h2">{{ text.title }}</h1>
      </div>

      <div class="custom-spacer"></div>

      <cta-product-base
        v-if="currentPackage"
        :showButton="false"
        :product="currentPackage"
        :page="'single'"
        :img="currentPackage.img_url"
      />

      <div class="product-back-link" v-if="offer.packages.length > 1">
        <router-link
          :to="{
            name: 'OfferPackagesPage',
            params: { uuid: $route.params.uuid }
          }"
          >Paket ändern…
        </router-link>
      </div>

      <div class="offer-form-block" id="form_start">
        <div class="form-grid form-grid-col">
          <div class="heading-col">
            <h2 class="color-primary-100">Kundendaten</h2>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Anrede</span>

            <span class="form-grid-item-value">{{ offer.user.Anrede }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Vorname</span>

            <span class="form-grid-item-value">{{ offer.user.Vorname }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Nachname</span>

            <span class="form-grid-item-value">{{ offer.user.Nachname }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Adresse</span>

            <span class="form-grid-item-value">
              {{
                `${offer.user.Strasse} ${offer.user.Hausnr} ${offer.user.Hausnr_Ergaenzung}`
              }}
            </span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Postleitzahl</span>

            <span class="form-grid-item-value">{{ `${offer.user.PLZ}` }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Ort</span>

            <span class="form-grid-item-value">{{ `${offer.user.Ort}` }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Email*</span>

            <span class="form-grid-item-value">
              <input-regular
                :type="'email'"
                :placeholder="offer.user.Email"
                :showLabel="false"
                :name="'email'"
                v-model="fields.email.value"
                :validation="fields.email"
                @errorExist="passToValidFields('email', $event)"
                @emailCheck="emailCheck()"
              />
            </span>
          </div>

          <div class="form-grid-item form-grid-item-hint">
            <span class="form-grid-item-title"></span>

            <span class="form-grid-item-value">
              <small class="text-small color-accent-300">
                Bitte nutzen Sie die gleiche E-Mailadresse, die Sie für Ihr
                “mein eprimo” Konto verwenden.
              </small>
            </span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Email wiederholen*</span>

            <span class="form-grid-item-value">
              <input-regular
                v-model="fields.email_confirm.value"
                :type="'email'"
                :name="'email_confirm'"
                :showLabel="false"
                :validation="fields.email_confirm"
                @errorExist="passToValidFields('email_confirm', $event)"
                @emailCheck="emailCheck()"
              />
            </span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Telefonnummer</span>

            <span class="form-grid-item-value">
              <input-regular
                v-model="fields.telefonnummer.value"
                :validation="fields.telefonnummer"
                :name="'telefonnummer'"
                type="text"
                :showLabel="false"
                @errorExist="passValid('telefonnummer', $event)"
              />
            </span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Zählernummer</span>

            <span class="form-grid-item-value">{{ offer.user.MeterID }}</span>
          </div>

          <div class="form-grid-item">
            <span class="form-grid-item-title">Kundennummer</span>

            <span class="form-grid-item-value">{{ offer.user.VK }}</span>
          </div>

          <div class="heading-col top">
            <h2 class="color-primary-100">Zahlungsdaten</h2>
          </div>

          <div class="form-grid-item checkbox">
            <span class="form-grid-item-title">Zahlung</span>

            <span class="form-grid-item-value">
              <div class="prices-row">
                <cta-product-price
                  v-model="fields['payment']"
                  :data="ePackage"
                  :offerType="offerType"
                />
              </div>
            </span>
          </div>

          <div class="form-grid-item radio-block">
            <span class="form-grid-item-title">Zahlung per</span>

            <div class="form-grid-item-value">
              <div class="radio-group-row">
                <div class="radio-group-item">
                  <input-radio
                    v-model="fields.paymentBy"
                    :label="'Lastschrift'"
                    name="example-1"
                    :value="false"
                  />
                </div>

                <div class="radio-group-item">
                  <input-radio
                    v-model="fields.paymentBy"
                    :label="'Überweisung'"
                    name="example-1"
                    :value="true"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-grid-item" v-if="!fields.paymentBy">
            <span class="form-grid-item-title">Kontoinhaber</span>

            <span class="form-grid-item-value">
              <input-regular
                v-model="fields.kontoinhaber.value"
                :validation="fields.kontoinhaber"
                type="text"
                :showLabel="false"
                :name="'kontoinhaber'"
                @errorExist="passValid('kontoinhaber', $event)"
              />
            </span>
          </div>

          <div class="form-grid-item" v-if="!fields.paymentBy">
            <span class="form-grid-item-title">IBAN</span>

            <span class="form-grid-item-value">
              <input-regular
                v-model="fields.iban.value"
                :name="'iban'"
                type="text"
                :validation="fields.iban"
                :showLabel="false"
                @errorExist="passValid('iban', $event)"
              />
            </span>
          </div>

          <div class="form-grid-item radio-block radio-block-wider">
            <span class="form-grid-item-title">{{ payLabel }}</span>

            <span class="form-grid-item-value">
              <div class="radio-group-row" v-if="fields.payment === MONTHLY">
                <div class="radio-group-item">
                  <input-radio
                    :name="'due'"
                    v-model="fields.dueTo"
                    :value="1"
                    :label="'zum 1. eines Monats'"
                  />
                </div>

                <div class="radio-group-item">
                  <input-radio
                    :name="'due'"
                    v-model="fields.dueTo"
                    :value="15"
                    :label="'zum 15. eines Monats'"
                  />
                </div>
              </div>

              <div
                class="radio-group-row"
                v-if="fields.payment === ONE_TIME_PAYMENT"
              >
                <p>zum 15. des Startmonats Ihres Pakets</p>
              </div>
            </span>
          </div>

          <div
            v-if="!fields.paymentBy"
            class="form-grid-item checkbox default-checkbox"
          >
            <div class="form-grid-item-title">
              <input-controller
                :type="'checkbox'"
                :dir="'right'"
                :showLabel="false"
                v-model="fields.lastschriftmandat.value"
                :name="'lastschriftmandat'"
                :valid="fields.lastschriftmandat.valid"
                @check="clearError('lastschriftmandat')"
              />
            </div>

            <div class="form-grid-item-value">
              <small class="text-small text-accent-300">
                Ich ermächtige die eprimo GmbH (Gläubiger-Identifikationsnummer
                DE12ZZZ00000080651) widerruflich, fällige Beträge per
                Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut
                an, die von eprimo GmbH auf mein Konto gezogenen Lastschriften
                einzulösen.<br />
                Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem
                Belastungsdatum, die Erstattung des belasteten Betrages
                verlangen. Es gelten dabei die mit meinem Kreditinstitut
                vereinbarten Bedingungen.
              </small>

              <div class="error-wrapper" v-if="!fields.lastschriftmandat.valid">
                <span class="error-message">
                  {{ fields.lastschriftmandat.error }}
                </span>
              </div>
            </div>
          </div>

          <div class="heading-col info-block">
            <h2 class="color-primary-100">Rechtliche Information</h2>
          </div>

          <div class="form-grid-item form-grid-item-wider">
            <telefon-service />

            <div>
              <div class="links">
                <p>
                  <a
                    :href="`${publicPath}version3/agb_strom_010322.pdf`"
                    target="_blank"
                    >AGB</a
                  >
                  &
                  <a
                    :href="
                      `${publicPath}version3/agb_ergaenzende_Bedingungen_Strompaket_220621.pdf`
                    "
                    target="_blank"
                    >Strompakete AGB</a
                  >
                </p>

                <a
                  :href="
                    `${publicPath}version3/datenschutz_information_220622.pdf`
                  "
                  target="_blank"
                  >Datenschutzinformation</a
                >

                <p @click="customerServiceModal = true">Kundenservice</p>

                <a
                  :href="`${publicPath}version3/Widerrufsformular_220621.pdf`"
                  target="_blank"
                  >Widerufsrecht</a
                >

                <p @click="contractConclusionModal = true">
                  Informationen zum Vertragabschluss
                </p>
              </div>

              <small
                class="text-small color-accent-300"
                v-html="text.rechtliche"
              ></small>

              <div
                class="form-grid-item checkbox positioned-checkbox default-checkbox"
              >
                <div class="form-grid-item-title">
                  <input-controller
                    :type="'checkbox'"
                    :dir="'right'"
                    :showLabel="false"
                    :name="'rechtliche_zustimmung'"
                    :valid="fields.rechtliche_zustimmung.valid"
                    v-model="fields.rechtliche_zustimmung.value"
                    @check="clearError('rechtliche_zustimmung')"
                  />
                </div>

                <div class="form-grid-item-value">
                  <small class="text-small text-accent-300">
                    Ich habe die AGB und Ergänzende AGB der Strompakete zur
                    Kenntnis genommen und stimme diesen zu. Ebenfalls habe ich
                    die Widerrufsbelehrung und die Hinweise auf
                    Beschwerdemöglichkeiten zur Kenntnis genommen.
                  </small>

                  <div
                    v-if="!fields.rechtliche_zustimmung.valid"
                    class="error-wrapper"
                  >
                    <span class="error-message">
                      {{ fields.rechtliche_zustimmung.error }}
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="form-grid-item checkbox positioned-checkbox default-checkbox"
              >
                <div class="form-grid-item-title">
                  <input-controller
                    :type="'checkbox'"
                    :dir="'right'"
                    :showLabel="false"
                    :name="'optin_pdf'"
                    :valid="fields.optin_pdf.valid"
                    v-model="fields.optin_pdf.value"
                    @check="clearError('optin_pdf')"
                  />
                </div>

                <div class="form-grid-item-value">
                  <small class="text-small text-accent-300">
                    Ich habe die für mich und in meinem Namen erzeugte,
                    <a @click="downloadPDF()" class="link"
                      >schriftliche Bestellung</a
                    >
                    des gewählten Produkts zur Kenntnis genommen und
                    heruntergeladen.
                  </small>

                  <div class="error-wrapper" v-if="!fields.optin_pdf.valid">
                    <span class="error-message">
                      {{ fields.optin_pdf.error }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-button-wide">
            <button-regular
              :isLoading="isLoading"
              @click="submitOrder()"
              :class="'button-accent'"
              >Jetzt kostenpflichtige Bestellung absenden
            </button-regular>
          </div>

          <modal-window
            :showModal="customerServiceModal"
            :classAdd="'info-modal'"
          >
            <div class="close-modal-wrap">
              <button
                @click="customerServiceModal = false"
                class="close-modal"
              ></button>
            </div>

            <div id="customer_service_modal">
              <h3>Kundenbetreuung und Kundenbeschwerden</h3>

              <div class="modal-body">
                <p>
                  Für evtl. Beanstandungen stehen Ihnen die u.a. Kontaktwege zur
                  Verfügung. eprimo wird Ihre Beanstandung binnen einer Frist
                  von 4 Wochen beantworten.
                </p>

                <p>
                  Schriftlich: eprimo GmbH, Abteilung Kundenservice,
                  Flughafenstr. 20, 63263 Neu-Isenburg
                </p>

                <p>
                  Telefonisch: Service-Hotline 0800/60 60 110 (kostenlos aus dem
                  dt. Festnetz), für Anrufe aus einem Mobilfunknetz: 069/80 88
                  12 34 (es entstehen die gemäß Ihrem Mobilfunkvertrag üblichen
                  Verbindungskosten für Anrufe ins dt. Festnetz)
                </p>

                <p>
                  Email:
                  <a href="mailto:kundenservice@eprimo.de"
                    >kundenservice@eprimo.de</a
                  >
                </p>

                <p>
                  Sollte Ihrer Beanstandung nicht innerhalb der unter Ziffer
                  20.1 der Allgemeinen Geschäftsbedingungen benannten Frist
                  abgeholfen werden, können Sie sich unter den Voraussetzungen
                  des § 111b EnWG an die Schlichtungsstelle Energie e.V.,
                  Friedrichstraße 133, 10117 Berlin,
                  <a
                    href="http://www.schlichtungsstelle-energie.de"
                    target="_blank"
                    >www.schlichtungsstelle-energie.de</a
                  >,
                  <a href="mailto:info@schlichtungsstelle-energie.de"
                    >info@schlichtungsstelle-energie.de</a
                  >, 030/27 57 240-0 wenden. eprimo ist zur Teilnahme an dem
                  Streitbeilegungsverfahren gesetzlich verpflichtet. Das Recht
                  der Beteiligten, die Gerichte anzurufen oder ein anderes
                  Verfahren nach dem EnWG zu beantragen, bleibt unberührt. Durch
                  ein etwaiges Schlichtungsverfahren wird die Verjährung gemäß §
                  204 Abs. 1 Nr.4 BGB gehemmt.
                </p>

                <button-regular
                  @click="printModal('customer_service_modal')"
                  :class="'button-cancel'"
                  >Drucken</button-regular
                >
              </div>
            </div>
          </modal-window>

          <modal-window
            :showModal="contractConclusionModal"
            :classAdd="'info-modal'"
          >
            <div class="close-modal-wrap">
              <button
                @click="contractConclusionModal = false"
                class="close-modal"
              ></button>
            </div>

            <div id="contract_conclusion_modal">
              <h3>Informationen im elektronischen Geschäftsverkehr</h3>

              <div class="modal-body">
                <h4>
                  1. Technische Schritte zum Vertragsschluss, Eingabefehler
                </h4>

                <p>
                  Nachdem Sie in unserem Tarifrechner einen günstigen Tarif
                  ausgewählt haben (Button „Tarif auswählen“), startet der
                  schnelle und einfache Bestellprozess. Sie werden in weiteren
                  Schritten zur Eingabe Ihrer Daten und der erforderlichen
                  Informationen für die Einleitung des Wechselprozesses
                  aufgefordert. Vor dem Absenden Ihrer Bestellung können Sie auf
                  der Bestellübersichtsseite Ihre Angaben prüfen und über die
                  Links „ändern“ mögliche Eingabefehler berichtigen.
                </p>

                <p>
                  Mit der Absendung Ihrer Bestellung über den Button
                  „zahlungspflichtig bestellen“ geben Sie eine verbindliche
                  Vertragserklärung ab. Die Bestätigung des Eingangs der
                  Bestellung, folgt unmittelbar nach dem technisch einwandfreien
                  Eingang Ihrer Bestellung. Diese Eingangsbestätigung stellt
                  noch keine Annahme Ihrer Vertragserklärung dar, sondern soll
                  Sie nur darüber informieren, dass Ihre Bestellung bei uns
                  eingegangen ist und kann für Archivierungszwecke verwendet
                  werden. Das Zustandekommen eines verbindlichen Vertrages
                  bestimmt sich nach Ziff. 3 unserer
                  <a href="https://www.eprimo.de/ueber-eprimo/agb/">AGB</a>.
                </p>

                <h4>2. Speicherung Vertragstext, Vertragssprache</h4>

                <p>
                  Unsere AGB, Datenschutzinformation und die weiteren
                  Vertragsbestimmungen mit den Daten Ihrer Bestellung, halten
                  wir auf unseren Bestellseiten zum Abruf bereit. Es besteht
                  dort die Möglichkeit die Daten als PDF herunterzuladen oder
                  über die Funktionen des von Ihnen genutzten Browsers zu
                  speichern.
                </p>

                <p>
                  Darüber hinaus lassen wir Ihnen die Vertragsbestimmungen noch
                  einmal im Nachgang zu Ihrer Bestellung zukommen, per Post oder
                  sofern Sie sich dort anmelden, in unserem Kundenportal „mein
                  eprimo“. Darüber hinaus wird der Vertragstext bei uns
                  gespeichert, ist aber aus Sicherheitsgründen, soweit Sie sich
                  nicht für unser Kundenportal „mein eprimo“ registriert haben,
                  nicht unmittelbar von Ihnen abrufbar. Über unser Kundeportal
                  „mein eprimo“ haben Sie mittels eines passwortgeschützten
                  direkten Zugangs die Möglichkeit, Ihre Daten zu verwalten. Die
                  AGB finden Sie in der jeweils gültigen Fassung auf unserer
                  Webseite www.eprimo.de. Verträge auf unserer Seite
                  www.eprimo.de lassen sich derzeit nur in deutscher Sprache
                  schließen.
                </p>

                <h4>3. Verhaltenskodizes der eprimo GmbH</h4>

                <p>
                  Die eprimo GmbH, als Teil der innogy SE, diese wiederum Teil
                  der E.ON SE und der damit verbundenen Unternehmen, unterwirft
                  sich dem Verhaltenskodex des E.ON Konzerns. Diesen Kodex
                  finden Sie im Internet unter: www.eon.com/verhaltenskodex.
                </p>

                <button-regular
                  @click="printModal('contract_conclusion_modal')"
                  :class="'button-cancel'"
                  >Drucken</button-regular
                >
              </div>
            </div>
          </modal-window>
        </div>
      </div>
    </section-base>

    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 mobile-h2">
          Die eprimo Strompakete kurz erklärt
        </h1>
      </div>
      <main-video />
    </section-base>

    <section-base>
      <accordion-faq>
        <div class="heading-col" slot="title">
          <h1 class="color-primary-100 mobile-h2">Haben Sie Fragen?</h1>
        </div>
      </accordion-faq>
    </section-base>
  </main>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import InputRegular from '@/components/next/input/input-regular.vue';
import CtaProductBase from '@/components/next/cta/cta-product-base.vue';
import CtaProductPrice from '@/components/next/cta/cta-product-price.vue';
import AccordionFaq from '@/components/next/accordion/accordion-faq.vue';
import InputController from '@/components/next/input/input-controller.vue';
import InputRadio from '@/components/next/input/input-radio.vue';
import ModalWindow from '@/components/next/modal-window.vue';
import TelefonService from '@/components/next/telefon-service.vue';
import MainVideo from '@/components/next/main-video.vue';
import { validateFields, fields } from '@/placeholder/fieldsValidation';
import { checkError } from '../../types/errors';
import { mapState } from 'vuex';

export default {
  components: {
    SectionBase,
    ButtonRegular,
    TelefonService,
    ModalWindow,
    InputRadio,
    InputController,
    AccordionFaq,
    CtaProductPrice,
    CtaProductBase,
    InputRegular,
    MainVideo
  },
  data() {
    return {
      MONTHLY: 'Monatliche',
      ONE_TIME_PAYMENT: 'Einmalzahlung',
      validateFields,
      fields,
      pdfsrc: null,
      errorsNotExist: true,
      isLoading: false,
      error: '',
      customerServiceModal: false,
      contractConclusionModal: false,
      publicPath: process.env.BASE_URL
    };
  },
  computed: {
    ...mapState('user', [
      'offer',
      'currentPackage',
      'currentPackageImgUrl',
      'offerType'
    ]),
    text() {
      let text;

      if (this.offerType === 'initial') {
        text = {
          title: 'Hier ist Ihr persönliches Angebot',
          rechtliche:
            'Wenn Sie sich dazu entscheiden, dieses Angebot zu nutzen\n' +
            '                verpflichten Sie sich, monatliche Zählerstände an eprimo zu\n' +
            '                übermitteln. Eine Erinnerung übersenden wir an Ihre oben\n' +
            '                genannte E-Mail-Adresse. Falls Sie einen Smartmeter haben,\n' +
            '                entfällt die Verpflichtung zur monatlichen Meldung an eprimo.'
        };
      } else {
        text = {
          title: 'Vielen Dank, wir haben Ihre Bestellung vorbereitet',
          rechtliche:
            'Wenn Sie sich dazu entscheiden, dieses Angebot zu nutzen verpflichten Sie sich, monatliche Zählerstände an eprimo zu übermitteln. Eine Erinnerung übersenden wir an Ihre oben genannte. <br>\n' +
            'E-Mail-Adresse. Falls Sie einen Smartmeter haben, entfällt die Verpflichtung zur monatlichen Meldung an eprimo.'
        };
      }
      return text;
    },
    payLabel() {
      let FAELLIGKEIT = 'Abbuchung zum',
        UBERWEISUNG = 'Überweisung zum',
        ONE_TIME = 'Fälligkeit der Rechnung zum';

      let DURATION = this.fields.payment;

      let IS_ACCOUNT = this.fields.paymentBy;
      let result;

      if (DURATION === this.MONTHLY && IS_ACCOUNT) {
        result = UBERWEISUNG;
      }
      if (DURATION === this.MONTHLY && !IS_ACCOUNT) {
        result = FAELLIGKEIT;
      }
      if (DURATION === this.ONE_TIME_PAYMENT && IS_ACCOUNT) {
        result = ONE_TIME;
      }
      if (DURATION === this.ONE_TIME_PAYMENT && !IS_ACCOUNT) {
        result = FAELLIGKEIT;
      }

      return result;
    },
    ePackage() {
      const product = this.currentPackage;
      return {
        onetime: {
          title: 'Einmalzahlung',
          value: this.formatPrice(product?.PriceOTP),
          brief: 'Einmalpreis für Paket',
          small: `(bereits rabbattiert, ${product?.DiscountOTP}% Skonto)`,
          sale: parseInt(product.DiscountOTP) > 0,
          discount: product.DiscountOTP
        },
        month: {
          title: `${product.EstimatedDuration} monatliche Raten`,
          value: this.formatPrice(product?.PriceMonthly),
          brief: `Gesamtpreis: <span> ${this.formatPrice(
            product?.PriceMonthly * product.EstimatedDuration
          )} €</span>`,
          small: `Reicht etwa: ${product.EstimatedDuration} Monate`,
          sale: parseInt(product.Discount) > 0,
          discount: product.Discount
        }
      };
    }
  },
  methods: {
    printModal(id) {
      var elem = document.getElementById(id);
      var domClone = elem.cloneNode(true);
      var $printSection = document.getElementById('print_section');

      if (!$printSection) {
        $printSection = document.createElement('div');
        $printSection.id = 'print_section';
        document.body.appendChild($printSection);
      }

      $printSection.innerHTML = '';
      $printSection.appendChild(domClone);
      window.print();
      $printSection.remove();
    },
    clearError(field) {
      this.fields[field].error = '';
      this.fields[field].valid = true;
    },
    validationFields(val) {
      let firstErrorField = '';
      this.errorsNotExist = true;

      this.validateFields.forEach(field => {
        this.clearError(field);
      });

      if (val === 'pdf') {
        let index = this.validateFields.indexOf('optin_pdf');
        if (index !== -1) {
          this.validateFields.splice(index, 1);
        }
      } else if (
        val === 'submit' &&
        !this.validateFields.includes('optin_pdf')
      ) {
        this.validateFields.push('optin_pdf');
      }

      this.validateFields.forEach(field => {
        if (
          (this.fields[field].value === '' || this.fields[field].value === 0) &&
          this.fields[field].visible
        ) {
          this.fields[field].valid = false;
          this.fields[field].error = 'Dieses Feld muss angegeben werden';
          this.errorsNotExist = false;
        }

        if (
          this.fields[field].type === 'checkbox' &&
          !this.fields[field].value &&
          this.fields[field].visible
        ) {
          this.fields[field].valid = false;
          this.fields[field].error = 'Bitte bestätigen Sie';
          this.errorsNotExist = false;
        }

        if (firstErrorField === '' && !this.errorsNotExist) {
          firstErrorField = field;
        }
      });

      if (this.fields.email.value !== this.fields.email_confirm.value) {
        this.fields.email_confirm.valid = false;
        this.fields.email_confirm.error =
          'Die E-Mail-Adressen stimmen nicht überein';
        this.errorsNotExist = false;
        firstErrorField = 'email_confirm';
      }

      if (!this.errorsNotExist) {
        const doc = document.querySelector(`input[name=${firstErrorField}]`);
        const y = doc.getBoundingClientRect().top + window.scrollY - 300;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }

      return this.errorsNotExist;
    },
    passToValidFields(field, event) {
      this.fields[field] = event;
    },
    emailCheck() {
      if (this.fields.email.value !== this.fields.email_confirm.value) {
        this.fields.email.error = 'Die E-Mail-Adressen stimmen nicht überein';
        this.fields.email.valid = false;
        this.fields.email_confirm.error =
          'Die E-Mail-Adressen stimmen nicht überein';
        this.fields.email_confirm.valid = false;
      } else {
        this.fields.email.error = '';
        this.fields.email.valid = true;
        this.fields.email_confirm.error = '';
        this.fields.email_confirm.valid = true;
      }
    },
    passValid(field, event) {
      this.fields[field] = event;
    },
    getSubmitData(val) {
      if (this.fields.payment === 'Einmalzahlung') {
        this.fields.dueTo = 15;
      }

      let info = {
        Email: this.fields.email.value,
        Email_Confirm: this.fields.email_confirm.value,
        Zahlung: this.fields.payment,
        Zahlung_Per: this.fields.paymentBy ? 'Überweisung' : 'Lastschrift',
        Rechtliche_Zustimmung: this.fields.rechtliche_zustimmung.value,
        Smart_Meter_Request: this.offer.user.SmartMeter_Offer,
        Faelligkeit_Zum: this.fields.dueTo,
        Kontoinhaber: this.fields.kontoinhaber.value,
        IBAN: this.fields.iban.value,
        Lastschriftmandat: this.fields.lastschriftmandat.value
      };

      if (this.fields.telefonnummer.value !== '') {
        info.Telefonnummer = this.fields.telefonnummer.value;
      }

      if (val === 'submit') {
        info.Optin_Pdf = this.fields.optin_pdf.value ? 1 : 0;

        return {
          user: info,
          offer_type: this.offerType,
          offer_id: this.$route.params.uuid,
          package: {
            NameSize: this.currentPackage.NameSize
          }
        };
      } else {
        info.package_id = this.currentPackage.Id;
        return info;
      }
    },
    submitOrder() {
      this.isLoading = true;

      let data = this.getSubmitData('submit');

      if (this.validationFields('submit')) {
        this.$store
          .dispatch('user/ORDER_SUBMIT', data)
          .then(res => {
            if (res.data.success) {
              this.$store.commit('user/SET_CURRENT_FLOW', 'offer_order');
              this.$router.push({ name: 'AuthUser' });
              this.$store.commit('user/SET_OFFER_INPUT_HISTORY', {
                email: this.fields.email.value,
                offer_id: this.$route.params.uuid
              });
            }
          })
          .catch(err => {
            this.isLoading = false;

            const msg = err.response.data.message;

            if (typeof msg == 'object') {
              for (const item of Object.entries(msg)) {
                let dataError = checkError(item);

                if (dataError?.field !== undefined) {
                  this.fields[dataError.field.name] = {
                    error: dataError.error_type,
                    valid: false,
                    value: this.fields[dataError.field.name].value,
                    visible: true
                  };

                  const doc = document.querySelector(
                    `input[name=${dataError.field.name}]`
                  );
                  const y =
                    doc.getBoundingClientRect().top + window.scrollY - 300;

                  window.scrollTo({ top: y, behavior: 'smooth' });
                } else {
                  this.error = item;
                }
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    downloadPDF() {
      let data = this.getSubmitData('pdf');

      if (this.validationFields('pdf')) {
        this.$store
          .dispatch('user/GENERATE_PDF', data)
          .then(res => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const objectUrl = URL.createObjectURL(blob);
            const outputFileName = 'Strompaket_Bestelldetails.pdf';

            if (typeof window.chrome !== 'undefined') {
              let link = document.createElement('a');
              link.href = objectUrl;
              link.download = outputFileName;
              link.click();
            } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
              window.navigator.msSaveBlob(blob, outputFileName);
            } else {
              var file = new File([res.data], outputFileName, {
                type: 'application/force-download'
              });
              window.open(URL.createObjectURL(file));
            }
          })
          .catch();
      }
    },
    formatPrice(value) {
      let val = value.toString().replace(',', '.');
      val = parseFloat(val)
        .toFixed(2)
        .replace('.', ',');
      return this.addDotToThousands(val);
    },
    addDotToThousands(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  },
  watch: {
    'fields.paymentBy'(val) {
      this.fields.kontoinhaber.visible = !val;
      this.fields.iban.visible = !val;
      this.fields.lastschriftmandat.visible = !val;

      // If Uberweisung
      if (val) {
        this.fields.iban.value = 0;
        this.fields.kontoinhaber.value = 0;
        this.fields.lastschriftmandat.value = false;
      }
    }
  },
  created() {
    this.$store.dispatch('user/GET_OFFER_DATA', {
      offer: this.$route.params.uuid,
      push: false
    });
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
