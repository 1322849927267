var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-product"},[_c('div',{staticClass:"cta-product-wrapper"},[_c('div',{staticClass:"cta-product-image",class:_vm.img},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.biggestPackageID === _vm.product.Id),expression:"biggestPackageID === product.Id"}],staticClass:"cta-product-label"},[_vm._v(" eprimo empfiehlt ")])]),_c('div',{staticClass:"cta-product-meta"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.product.Discount > 0),expression:"product.Discount > 0"}],staticClass:"discount"},[_vm._v(" -"+_vm._s(_vm.product.Discount)+"% ")]),_c('div',{staticClass:"cta-product-meta-row"},[_c('div',{staticClass:"cta-product-package"},[_c('h1',{staticClass:"cta-product-meta-title"},[_vm._v(" "+_vm._s(((_vm.product.Name) + " " + (_vm.product.NameSize)))+" ")]),_c('cta-list-base',[_c('div',{staticClass:"cms-list",domProps:{"innerHTML":_vm._s(_vm.product.html)}})])],1),_c('div',{staticClass:"cta-product-prices"},[_c('div',{staticClass:"cta-product-prices-inner"},[_c('p',{staticClass:"price-brief"},[_vm._v("Monatliche Rate bei Ratenkauf")]),_c('p',{staticClass:"big-text text-style-bold price-number"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.product.PriceMonthly))+" €"),_c('sup',[_vm._v("2")])]),(_vm.page === 'archive')?[_c('p',{staticClass:"price-year"},[_vm._v("Gesamtpreis für Paket:")]),_c('p',[_vm._v(" "+_vm._s(_vm.formatPrice( _vm.product.PriceMonthly * _vm.product.EstimatedDuration ))+" €"),_c('sup',{staticClass:"text-small"},[_vm._v("3")])])]:_vm._e(),(_vm.page === 'single')?[_c('p',{staticClass:"price-year"},[_vm._v("Einmalpreis für Paket:")]),_c('p',{},[_vm._v(_vm._s(_vm.formatPrice(_vm.product.PriceOTP))+" €")])]:_vm._e(),_c('button-regular',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"button-accent price-button",on:{"click":function($event){_vm.$router.push({
                  name: 'orderPackage',
                  params: {
                    uuid: _vm.$route.params.uuid
                  }
                }),
                  (_vm.product.img_url = _vm.img);
                _vm.$store.commit('user/SET_CURRENT', _vm.product);}}},[_vm._v("Zum Angebot ")])],2)])]),_c('div',{staticClass:"cta-product-meta-row-mobile"},[_c('div',{staticClass:"cta-product-meta-mobile"},[_c('h2',{staticClass:"cta-product-meta-title"},[_vm._v(" "+_vm._s(((_vm.product.Name) + " " + (_vm.product.NameSize)))+" ")]),_c('p',{staticClass:"price-brief"},[_vm._v("Monatliche Rate bei Ratenkauf")]),_c('h2',{staticClass:"price-value"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.product.PriceMonthly))+" €"),_c('sup',[_vm._v("2")])]),(_vm.page === 'archive')?_c('p',{staticClass:"price-year"},[_vm._v(" Gesamtpreis für Paket: "+_vm._s(_vm.formatPrice(_vm.product.PriceMonthly * _vm.product.EstimatedDuration))+" €"),_c('sup',{staticClass:"text-small"},[_vm._v("3")])]):_vm._e(),(_vm.page === 'single')?_c('p',{staticClass:"price-year"},[_vm._v(" Einmalpreis für Paket: "+_vm._s(_vm.formatPrice(_vm.product.PriceOTP))+" € ")]):_vm._e()]),_c('div',{staticClass:"cta-product-meta-separator"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.biggestPackageID === _vm.product.Id),expression:"biggestPackageID === product.Id"}],staticClass:"cta-product-label"},[_vm._v(" eprimo empfiehlt ")])]),_c('div',{staticClass:"cta-product-meta-features"},[_c('cta-list-base',[_c('div',{staticClass:"cms-list",domProps:{"innerHTML":_vm._s(_vm.product.html)}})]),_c('div',{staticClass:"cta-product-meta-features-btn"},[_c('button-regular',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"button-accent price-button",on:{"click":function($event){_vm.$router.push({
                  name: 'orderPackage',
                  params: {
                    uuid: _vm.$route.params.uuid,
                    ePackage: _vm.product
                  }
                }),
                  (_vm.product.img_url = _vm.img);
                _vm.$store.commit('user/SET_CURRENT', _vm.product);}}},[_vm._v("Zum Angebot ")])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }