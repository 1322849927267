<template>
  <div class="telefon-service">
    <p class="telefon-service-title">Haben Sie Fragen?</p>

    <div class="telefon-service-icon"></div>

    <p class="telefon-service-subtitle">
      Rufen Sie uns an:
      <br />
      0800-204011093
    </p>

    <small class="text-small telefon-service-small">
      Diese Rufnummer ist aus dem deutschen Festnetz kostenlos.
      <br />
      Unsere Service-Zeiten sind montags – freitags von 9.00 – 18.00 Uhr.
    </small>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
