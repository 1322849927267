<template>
  <div class="form-item-checkbox">
    <label :class="`control control--${type} `" :for="`form-${type}-${name}`">
      <p v-show="showLabel" class="label">{{ label }}</p>

      <input
        :id="`form-${type}-${name}`"
        :placeholder="placeholder"
        :type="type"
        :name="name"
        @input="$emit('input', $event.target.checked)"
        @change="getCheckboxValue()"
      />

      <div
        class="control__indicator"
        :class="[{ 'error-indicator': !valid }, dir]"
      ></div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    type: {
      type: String
    },
    dir: {
      type: String,
      default: 'left'
    },
    placeholder: {
      type: String
    },
    valid: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getCheckboxValue() {
      this.$emit('check', true);
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
