<template>
  <div class="cta-product">
    <div class="cta-product-wrapper">
      <div class="cta-product-image" :class="img">
        <div v-show="biggestPackageID === product.Id" class="cta-product-label">
          eprimo empfiehlt
        </div>
      </div>

      <div class="cta-product-meta">
        <div v-show="product.Discount > 0" class="discount">
          -{{ product.Discount }}%
        </div>

        <div class="cta-product-meta-row">
          <div class="cta-product-package">
            <h1 class="cta-product-meta-title">
              {{ `${product.Name} ${product.NameSize}` }}
            </h1>

            <cta-list-base>
              <div v-html="product.html" class="cms-list"></div>
            </cta-list-base>
          </div>

          <div class="cta-product-prices">
            <div class="cta-product-prices-inner">
              <p class="price-brief">Monatliche Rate bei Ratenkauf</p>

              <p class="big-text text-style-bold price-number">
                {{ formatPrice(product.PriceMonthly) }} €<sup>2</sup>
              </p>

              <template v-if="page === 'archive'">
                <p class="price-year">Gesamtpreis für Paket:</p>

                <p>
                  {{
                    formatPrice(
                      product.PriceMonthly * product.EstimatedDuration
                    )
                  }}
                  €<sup class="text-small">3</sup>
                </p>
              </template>

              <template v-if="page === 'single'">
                <p class="price-year">Einmalpreis für Paket:</p>

                <p class="">{{ formatPrice(product.PriceOTP) }} €</p>
              </template>

              <button-regular
                v-show="showButton"
                class="button-accent price-button"
                @click="
                  $router.push({
                    name: 'orderPackage',
                    params: {
                      uuid: $route.params.uuid
                    }
                  }),
                    (product.img_url = img);
                  $store.commit('user/SET_CURRENT', product);
                "
                >Zum Angebot
              </button-regular>
            </div>
          </div>
        </div>

        <div class="cta-product-meta-row-mobile">
          <div class="cta-product-meta-mobile">
            <h2 class="cta-product-meta-title">
              {{ `${product.Name} ${product.NameSize}` }}
            </h2>

            <p class="price-brief">Monatliche Rate bei Ratenkauf</p>

            <h2 class="price-value">
              {{ formatPrice(product.PriceMonthly) }} €<sup>2</sup>
            </h2>

            <p v-if="page === 'archive'" class="price-year">
              Gesamtpreis für Paket:
              {{
                formatPrice(product.PriceMonthly * product.EstimatedDuration)
              }}
              €<sup class="text-small">3</sup>
            </p>

            <p v-if="page === 'single'" class="price-year">
              Einmalpreis für Paket: {{ formatPrice(product.PriceOTP) }} €
            </p>
          </div>

          <div class="cta-product-meta-separator">
            <div
              v-show="biggestPackageID === product.Id"
              class="cta-product-label"
            >
              eprimo empfiehlt
            </div>
          </div>

          <div class="cta-product-meta-features">
            <cta-list-base>
              <div v-html="product.html" class="cms-list"></div>
            </cta-list-base>

            <div class="cta-product-meta-features-btn">
              <button-regular
                v-show="showButton"
                class="button-accent price-button"
                @click="
                  $router.push({
                    name: 'orderPackage',
                    params: {
                      uuid: $route.params.uuid,
                      ePackage: product
                    }
                  }),
                    (product.img_url = img);
                  $store.commit('user/SET_CURRENT', product);
                "
                >Zum Angebot
              </button-regular>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CtaListBase from '@/components/next/cta/cta-list-base.vue';
import ButtonRegular from '@/components/next/button-regular.vue';

export default {
  components: { CtaListBase, ButtonRegular },

  props: {
    product: Object,
    showButton: Boolean,
    page: String,
    img: String
  },

  computed: {
    ...mapState('user', ['biggestPackageID']),
    productFeatures() {
      const feature =
        this.product.Discount === 0
          ? 'Keine Mindestlaufzeit'
          : `Rabatt Paketgröße: ${this.product.Discount}%`;

      return [
        `Menge: ${this.product.kwhQuantity} kWh`,
        `Reicht etwa: ${this.product.EstimatedDuration} Monate`,
        feature
      ];
    }
  },

  methods: {
    formatPrice(value) {
      let val = value.toString().replace(',', '.');
      val = parseFloat(val)
        .toFixed(2)
        .replace('.', ',');
      return this.addDotToThousands(val);
    },
    addDotToThousands(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>

<style lang="scss">
.cms-list ol li {
  list-style-position: inside;
}
.cms-list ul li {
  display: flex;
  list-style: none;
  gap: 1rem;
  font-size: 1.2rem;
  align-items: center;
  &::before {
    display: block;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    margin-top: 5px;
    background-image: url('../../../assets/images/images-next/checkmark.svg');
    background-size: contain;
    content: '';
  }
}
</style>
