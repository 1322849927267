<template>
  <div class="cta-product-price-col">
    <div class="cta-product-price-grid">
      <div class="cta-product-price-wrapper">
        <div v-if="data.onetime.sale" class="sale">{{ `-${data.onetime.discount}%` }}</div>

        <div
            class="cta-product-price-card"
            :class="{ active: chosenPrice }"
            @click="chosenPrice = true, $emit('input', 'Einmalzahlung')"
        >
          <span class="card-price-title">{{ data.onetime.title }}</span>

          
          <span class="card-price-value">{{ `${data.onetime.value} €` }}</span>
          <span class="card-price-brief">{{ data.onetime.brief }}</span>
          <span class="card-price-small" v-if="data.onetime.sale">{{ data.onetime.small }}</span>
        </div>
      </div>

      <div class="cta-product-price-wrapper">
        <div v-if="data.month.sale" class="sale">{{ `-${data.month.discount}%` }}</div>

        <div
            class="cta-product-price-card"
            :class="{ active: !chosenPrice }"
            @click="chosenPrice = false, $emit('input', 'Monatliche')"
        >
          <span class="card-price-title">{{ data.month.title }}</span>
          <span class="card-price-value">{{ `${data.month.value} €` }}<sup>2</sup></span>
          <span class="card-price-brief" v-html="data.month.brief"></span>
          <span class="card-price-small">{{ data.month.small }}<sup>1</sup></span>
        </div>
      </div>
    </div>


    <small class="text-small color-accent-300">
      <sup>1</sup> Angaben sind geschätzt, basierend auf Ihren zurückliegenden Verbrauchsdaten gemäß Ihrer letzten Jahresrechnung.
    </small>
    
    <small class="text-small color-accent-300">
      <sup>2</sup> Sollten Sie sich für eine Ratenzahlung entscheiden, wird Ihnen eine
      Ratenzahlungsvereinbarung mit einem Ratenzahlungsplan in Ihr mein eprimo
      Postfach übersandt, aus dem Sie die jeweiligen Fälligkeiten entnehmen
      können. Die Gewährung der Ratenzahlung erfolgt zinsfrei über die
      <span v-if="offerType === 'following'">von Ihnen gewählte</span> Anzahl von Raten. Weitere Details entnehmen Sie bitte den hier einsehbaren
      <a :href="`${publicPath}version3/agb_ergaenzende_Bedingungen_Strompaket_220621.pdf`" target="_blank">Strompakete-AGB</a>.
    </small>

  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    offerType: String
  },

  data() {
    return {
      chosenPrice: true,
      publicPath: process.env.BASE_URL
    };
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
